$(function(){
  $('#accordion').accordion({
    heightStyle: "content"
  });
});

var anchoPantalla = jQuery(window).width();
var altoPantalla = jQuery(window).height();
var alturaItemCarou = jQuery('#destCarousel li').height();
var rescSku = jQuery('.rescatarSku').html();
var productCat = jQuery('.product-categories').html();
var linkPdf = jQuery('.woocommerce-Tabs-panel--description p').html();

jQuery(document).ready(function(){

  NProgress.start();
  setTimeout(function() {
    NProgress.done();
    jQuery('.fade').removeClass('out');
  }, 1500);

  var toggles = document.querySelectorAll(".c-hamburger");

  for (var i = toggles.length - 1; i >= 0; i--) {
    var toggle = toggles[i];
    toggleHandler(toggle);
  };

  function toggleHandler(toggle) {
    toggle.addEventListener( "click", function(e) {
      e.preventDefault();
      (this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
    });
  }

  jQuery('.woocommerce-checkout h2.pageTitle').append('<span>Espera la confirmación para hacer el pago. Recuerda que hay descuentos especiales y ofertas.<br><strong>Todo pedido paga despacho.<strong></span>');

  jQuery('.movilSearchIcon').click(function() {
    jQuery('.buscadorMobile').fadeToggle('fast');
  });

  jQuery('.productBoxInside del').closest('.productBox').append('<div class="ribbon addMargin"><span class="enVenta">¡Oferta!</span></div>');

  jQuery('ul.products li').each(function(i) {
    jQuery(this).addClass('itemProduct' + (i+1));
  });

  jQuery('.woocommerce-tabs').remove();
  jQuery('.descFootLinksPdf').attr('href',linkPdf);

  // $('#billing_myfield12').keypress(validateNumber);
  jQuery('#billing_myfield12').attr('maxlength', '9');
  jQuery('#billing_myfield12').Rut({
    on_error: function(){
      jQuery('#billing_myfield12_field').append('<span role="alert" class="wpcf7-not-valid-tip">El rut ingresado no es valido</span>');
      //jQuery('span.rutprop').append('<span role="alert" class="wpcf7-not-valid-tip">El rut ingresado no es valido</span>');
      jQuery('#billing_myfield12').val('');
    }
  });
  jQuery('#billing_myfield12').focus(function(){
    jQuery('.wpcf7-not-valid-tip').remove();
  });

  jQuery('.woocommerce-Price-amount').append('<small>Precio con IVA incluido</small>');

  jQuery("[data-fancybox]").fancybox({
    opacity: '0.2',
    slideShow: false,
  	fullScreen: false,
  	thumbs: false,
	});

  jQuery('.product-type-bundle td:first-child').addClass('monto');

  jQuery('.product_type_grouped').closest('.productBox').addClass('multipleProductBox');
  //jQuery('.product-type-bundle .qty').val('1');

  // Menu lateral
  if (jQuery(window).width() < 767) {
    jQuery('.ocultarLiEscritorio').remove();


    jQuery('.product-type-bundle .label').each(function () {
      if (!$(this).text().match(/^\s*$/)) {
        $(this).insertBefore($(this).prev('.product-type-bundle .monto'));
      }
    });
  }
  else {
    jQuery('.ocultarLiMovil').remove();
    //jQuery('.children .children').hide();
  }

  jQuery('.children').hide();

  jQuery('.error-404 span.count').remove();
  jQuery('.cat-parent a').html(function (i, html){
    return html.replace(/^[^a-zA-Z]*([a-zA-Z])/g, '<span class="big-cap">$1</span>');
  });
  jQuery('.cat-parent > a').append('<i class="ion-ios-arrow-thin-down"></i>');
  jQuery('.cat-parent > a').attr('href','#');
  //despliega solo el submenu de ese menu concreto
  jQuery('.cat-parent > a').click(function(event) {
    var elem = jQuery(this).next();
    if (elem.is('ul')) {
      event.preventDefault();
      elem.slideToggle(250);
    }
  });

  jQuery('a[href$=".pdf"]').each(function() {
    jQuery(this).addClass('docLink');
    jQuery(this).attr('target','_blank');
    jQuery(this).prepend('<i class="fa fa-download" aria-hidden="true"></i>');
  });

  // Elimina el sku por defecto y lo despliega en las areas definidas por diseño
  jQuery('.rescatarSku').remove();
  jQuery('.addSku').html(rescSku);

  jQuery('.c-hamburger').click(function(){
    jQuery('.menuMobile').toggleClass('menuMobileActive');
  });

  // Modifica html para estilo de banda de ofertas
  jQuery('span.onsale').addClass('enVenta').removeClass('onsale');
  jQuery('span.enVenta').wrap('<div class="ribbon"></div>');

  // Ejecuta skitter
  jQuery('.skitter-large').skitter({
    numbers: false,
    animation: 'directionLeft',
    progressbar: true,
    theme: 'minimalist'
  });

  // Añade clase secuencial a cada noticia
  jQuery('.singleNew').each(function(i) {
    jQuery(this).addClass('singleNewN0' + (i+1));
  });

  jQuery('.storefront-sorting').each(function(i) {
    jQuery(this).addClass('sortingN0' + (i+1));
  });

  jQuery('.next.page-numbers').html('<i class="ion-ios-arrow-right"></i>');
  jQuery('.prev.page-numbers').html('<i class="ion-ios-arrow-left"></i>');
  jQuery('.nextpostslink').html('<i class="ion-ios-arrow-right"></i>');
  jQuery('.previouspostslink').html('<i class="ion-ios-arrow-left"></i>');

  // jQuery('.singleNewN01, .singleNewN02').addClass('col-xs-12 col-sm-6');
  // jQuery('.singleNewN03, .singleNewN04, .singleNewN05').addClass('col-xs-12 col-sm-4');


  //Carrusel banners principal
  jQuery('#sliderCarousel').carouFredSel({
    responsive: true,
    items: 1,
    pagination: "#pager1",
    scroll: {
      items: 1,
      duration: 1000,
      pauseOnHover: true,
      easing: "quadratic"
    },
    auto: {
      pauseOnHover: 'resume',
      progress: '#timer1'
    },
    transition: true
  });


  // Carrusel de noticias
  jQuery('#newsCarousel').imagesLoaded(function() {
    jQuery('#newsCarousel').carouFredSel({
      responsive: true,
      width: '96%',
      height: 'variable',
      scroll: {
        items: 1,
        duration: 750,
        pauseOnHover: true,
        easing: 'quadratic'
      },
      items: {
        width: 400,
        height: 'variable',
        visible: {
          min: 1,
          max: 2
        }
      },
      prev: '#prev1',
      next: '#next1'
    });
  });


  // Carrusel de productos destacados
  jQuery('#destCarousel').imagesLoaded(function() {
    jQuery('#destCarousel').carouFredSel({
      responsive: true,
      width: '96%',
      height: 'variable',
      scroll: {
        items: 1,
        duration: 500,
        pauseOnHover: true,
        easing: 'swing'
      },
      items: {
        width: 350,
        height: 'variable',
        visible: {
          min: 1,
          max: 4
        }
      },
      prev: '#prev2',
      next: '#next2'
    });
  });


  // Carrusel de productos relacionados en mobile
  if (jQuery(window).width() < 767) {
    jQuery('.related ul').imagesLoaded(function() {
      jQuery('.related ul').carouFredSel({
        responsive: true,
        width: '96%',
        height: 'variable',
        scroll: {
          items: 1,
          duration: 750,
          pauseOnHover: true,
          easing: 'quadratic'
        },
        items: {
          width: 400,
          height: 'variable',
          visible: {
            min: 1,
            max: 2
          }
        },
        prev: '#prev1',
        next: '#next1'
      });
    });
  }
  else {
    jQuery('.scrollBar').mCustomScrollbar({
      theme:"dark"
    });
  }


  // Carrusel de descarga de boletines
  jQuery('.related ul').imagesLoaded(function() {
    jQuery('#boletinCarousel').carouFredSel({
      responsive: true,
      width: '96%',
      height: 'variable',
      scroll: {
        items: 1,
        duration: 500,
        pauseOnHover: true,
        easing: 'swing'
      },
      items: {
        width: 350,
        height: 'variable',
        visible: {
          min: 1,
          max: 4
        }
      },
      prev: '#prev3',
      next: '#next3'
    });
  });


  jQuery('del').parent('.price').css('margin-top','0px');

  jQuery('.site-header-cart').hover(
    function() {
      jQuery('.widget_shopping_cart').fadeIn();
    }, function() {
      jQuery('.widget_shopping_cart').fadeOut();
    }
  );

});
